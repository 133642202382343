import React, { useEffect, useState } from 'react';
import { Button, Input, Label, Loader, Modal, MultiSelect, Select, Upload } from '../../../../shared/ui';
import { Grid, SelectChangeEvent } from '@mui/material';
import ConnectField from '../../../../shared/ui/ConnectField/ConnectField';
import { useFormik } from 'formik';
import { useNotification } from '../../../../services/StorageAdapter';
import NumeratorModal from './NumeratorModal';
import { OptionType } from '../../../../shared/types';
import { IDocumentType } from '../../../../domain/documents';
import Axios from '../../../../shared/api/axios';
import { useLocation, useNavigate } from 'react-router-dom';
interface IProps {
    open: boolean;
    onClose: () => void;
    fetchFunnels: () => Promise<any>;
    fetchNumerators: () => Promise<any>;
    fetchDocuments: (arg: boolean) => Promise<any>;

    setSuccess: any;
}
const SettingsIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9499 10.7833C15.9833 10.5333 15.9999 10.275 15.9999 10C15.9999 9.73333 15.9833 9.46667 15.9416 9.21667L17.6333 7.9C17.7833 7.78333 17.8249 7.55833 17.7333 7.39167L16.1333 4.625C16.0333 4.44167 15.8249 4.38333 15.6416 4.44167L13.6499 5.24167C13.2333 4.925 12.7916 4.65833 12.2999 4.45833L11.9999 2.34167C11.9666 2.14167 11.7999 2 11.5999 2H8.39993C8.19993 2 8.04159 2.14167 8.00826 2.34167L7.70826 4.45833C7.2166 4.65833 6.7666 4.93333 6.35826 5.24167L4.3666 4.44167C4.18326 4.375 3.97493 4.44167 3.87493 4.625L2.28326 7.39167C2.18326 7.56667 2.2166 7.78333 2.38326 7.9L4.07493 9.21667C4.03326 9.46667 3.99993 9.74167 3.99993 10C3.99993 10.2583 4.0166 10.5333 4.05826 10.7833L2.3666 12.1C2.2166 12.2167 2.17493 12.4417 2.2666 12.6083L3.8666 15.375C3.9666 15.5583 4.17493 15.6167 4.35826 15.5583L6.34993 14.7583C6.76659 15.075 7.20826 15.3417 7.69993 15.5417L7.99993 17.6583C8.0416 17.8583 8.19993 18 8.39993 18H11.5999C11.7999 18 11.9666 17.8583 11.9916 17.6583L12.2916 15.5417C12.7833 15.3417 13.2333 15.075 13.6416 14.7583L15.6333 15.5583C15.8166 15.625 16.0249 15.5583 16.1249 15.375L17.7249 12.6083C17.8249 12.425 17.7833 12.2167 17.6249 12.1L15.9499 10.7833ZM9.99993 13C8.34993 13 6.99993 11.65 6.99993 10C6.99993 8.35 8.34993 7 9.99993 7C11.6499 7 12.9999 8.35 12.9999 10C12.9999 11.65 11.6499 13 9.99993 13Z"
            fill="#595959"
        />
    </svg>
);

const PlusIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.8332 10.8333H10.8332V15.8333H9.1665V10.8333H4.1665V9.16666H9.1665V4.16666H10.8332V9.16666H15.8332V10.8333Z"
            fill="#595959"
        />
    </svg>
);

interface ExtendedOptionType extends OptionType {
    stages?: Array<OptionType>;
    name?: string;
}

export interface INumerator {
    created: string;
    current_number: number;
    id: string;
    is_active: boolean;
    name: string;
    number_template: string;
}

const valuesMap: { [key: string]: boolean } = {
    roles_access: true,
    funnel_ids: true,
    funnel_stage_ids: true,
};

const AddGenerateDocumentModal = (props: IProps) => {
    const { open, onClose, fetchFunnels, fetchDocuments, fetchNumerators, setSuccess } = props;
    const [openNumerator, setOpenNumerator] = useState(false);
    const [funnels, setFunnels] = useState<ExtendedOptionType[]>([]);
    const [documents, setDocuments] = useState<OptionType[]>([]);
    const [stages, setStages] = useState<ExtendedOptionType[]>([]);
    const [numerators, setNumerators] = useState<OptionType[]>([]);
    const [numeratorIsEdit, setNumeratorIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const { notify } = useNotification();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { values, errors, handleSubmit, setFieldValue, setValues } = useFormik<any>({
        initialValues: {
            name: '',
            roles_access: null,
            funnel_ids: null,
            funnel_stage_ids: [],
            file_path: '',
            document_type: '',
            numerator: '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            const roles = (values.roles_access || []).map((i: { id: string }) => i.id);
            const rolesAccess = [];
            for (const role of roles) {
                const result = await Axios.post<{ role: string; access_level: string }, { id: string }>(
                    '/api/v1/documents/document-template-accesses',
                    {
                        role,
                        access_level: 'create',
                    }
                );
                rolesAccess.push(result.id);
            }
            const newValues = {
                ...values,
                roles_access: rolesAccess,
            };
            const formData = new FormData();
            const keys = Object.keys(newValues);

            for (const key of keys) {
                //@ts-ignore
                formData.append(key, valuesMap[key] ? newValues[key].join(';') : newValues[key]);
            }
            await Axios.post('/api/v1/documents/document-templates', formData)
                .then(() => {
                    notify({ message: 'Успешно создан' });
                    onClose();
                    setSuccess((prev: boolean) => !prev);
                })
                .finally(() => setLoading(false));
        },
    });

    useEffect(() => {
        if (search) {
            setLoading(true);
            const id = search.split('=')[1];
            Axios.get(`/api/v1/documents/document-templates/${id}`).then((result: any) => {
                Axios.get(
                    `/api/v1/funnels/funnels/${result.funnel_ids.map((i: { id: string }) => i.id).join()}`
                )
                    .then((stagesResult: any) => {
                        setStages(
                            stagesResult.stages.map((i: { name: string; id: string }) => ({
                                text: i.name,
                                id: i.id,
                            }))
                        );
                        setValues({
                            roles_access: result.roles_access.map((i: { role: string }) => i.role),
                            document_type: result.document_type?.id,
                            funnel_ids: result.funnel_ids.map((i: { id: string }) => i.id).join(),
                            funnel_stage_ids: result.funnel_stage_ids.map((i: { id: string }) => i.id),
                            numerator: result.numerator.id,
                            name: result.name,
                        });
                    })
                    .finally(() => setLoading(false));
            });
        }
    }, [search]);
    useEffect(() => {
        fetchFunnels().then(
            ({ results }: { results: { name: string; id: string; stages: OptionType[] }[] }) =>
                setFunnels(results.map((i) => ({ text: i.name, id: i.id, stages: i.stages })))
        );
        fetchDocuments(false).then(({ results }) => {
            setDocuments(
                results
                    .filter((i: any) => i.is_active)
                    .map((i: IDocumentType) => ({ text: i.name, id: i.id }))
            );
        });
        fetchNumerators().then(({ results }) => {
            setNumerators(results.map((i: { name: string; id: string }) => ({ text: i.name, id: i.id })));
        });
    }, []);

    const handleChangeFunnel = (e: SelectChangeEvent<any>) => {
        const value = e.target.value;
        setFieldValue('funnel_ids', [value]);
        const findStages = funnels.find((i) => i.id === value)?.stages;
        if (findStages) {
            setStages(findStages.map((i: any) => ({ text: i.name, id: i.id })));
        }
    };

    const handleAddNumerator = (item: OptionType) => {
        setNumerators((prev) => [...prev, item]);
        setTimeout(() => setFieldValue('numerator', item.id), 1000);
    };
    return (
        <Modal
            title="Добавить тип документа"
            width={700}
            open={open}
            onClose={() => {
                onClose();
                if (search) {
                    navigate('/template-documents');
                }
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Grid container sx={{ padding: '30px' }} spacing={3} alignItems="center">
                        <Grid item md={12}>
                            <Label label="Название шаблона" />
                            <Input
                                value={values.name}
                                onChange={(e) => setFieldValue('name', e.target.value)}
                                fullWidth
                                placeholder="Соглашение..."
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Label label="Тип документа" />
                            <Select
                                items={documents}
                                value={values['document_type']}
                                onChange={(e) => setFieldValue('document_type', e.target.value)}
                            />
                        </Grid>
                        <Grid item display="flex">
                            <ConnectField
                                error={errors['roles_access'] as string}
                                items={values['roles_access']}
                                label="Роли с доступом"
                                required
                                setValue={setFieldValue}
                                value={values['roles_access']}
                                field={'roles_access'}
                                modalTitle="Добавить сущность с доступом"
                                type={'m2m'}
                                code={'roles'}
                                isEdit={true}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Label label="Воронка с доступом" />
                            <Select
                                fullWidth
                                items={funnels}
                                value={values['funnel_ids']}
                                defaultValue={values['funnel_ids']}
                                onChange={handleChangeFunnel}
                            />
                        </Grid>
                        {values.funnel_ids && (
                            <Grid item md={12}>
                                <Label label="Этапы с доступом" />
                                <MultiSelect
                                    fullWidth
                                    items={stages}
                                    onChange={(e) => setFieldValue('funnel_stage_ids', e.target.value)}
                                    value={values['funnel_stage_ids']}
                                    onDelete={(items) => setFieldValue('funnel_stage_ids', items)}
                                />
                            </Grid>
                        )}
                        <Grid item md={7}>
                            <Label label="Используемый нумератор" />
                            <Select
                                items={numerators}
                                onChange={(e) => setFieldValue('numerator', e.target.value)}
                                value={values['numerator']}
                            />
                        </Grid>
                        {values.numerator && (
                            <Grid item md={2.5} sx={{ marginTop: '20px' }} display="flex" alignItems="center">
                                <SettingsIcon />
                                <div
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setOpenNumerator(true);
                                        setNumeratorIsEdit(true);
                                    }}
                                >
                                    Настроить
                                </div>
                            </Grid>
                        )}
                        <Grid item md={2.5} sx={{ marginTop: '20px' }} display="flex" alignItems="center">
                            <PlusIcon />
                            <div
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={() => setOpenNumerator(true)}
                            >
                                Создать
                            </div>
                        </Grid>
                        <Grid item>
                            <Label label="Файл" />
                            <Upload
                                withoutPreloader
                                format=".DOCX"
                                accept={{
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                        ['.docx', '.doc'],
                                }}
                                uploadedFiles={(files) => {
                                    setFieldValue('file_path', files[0].file);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ paddingRight: '30px' }}>
                        <Button
                            type="submit"
                            sx={{ marginRight: '20px' }}
                            variant="contained"
                            loading={loading}
                        >
                            Добавить
                        </Button>
                        <Button variant="outlined">Отменить</Button>
                    </Grid>
                </form>
            )}
            {openNumerator && (
                <NumeratorModal
                    handleAddNumerator={handleAddNumerator}
                    open={openNumerator}
                    numeratorId={values.numerator}
                    isEdit={numeratorIsEdit}
                    onClose={() => {
                        setOpenNumerator(false);
                        setNumeratorIsEdit(false);
                    }}
                />
            )}
        </Modal>
    );
};

export default AddGenerateDocumentModal;
