export const documentTypesHeaders = [
    {
        id: 'name',
        name: 'Тип документа',
    },
    {
        id: 'metaName',
        name: 'Сущность с доступом',
    },
    {
        id: 'status',
        name: '',
    },
];

export const documentGeneratedHeaders = [
    {
        id: 'name',
        name: 'Название шаблона',
        value_type: 'edit',
    },
    {
        id: 'entityAccess',
        name: 'Сущность с доступом',
    },
    {
        id: 'roleAccess',
        name: 'Роли с доступом',
    },
    {
        id: 'funnel_stage',
        name: 'Этапы с доступом',
    },
    {
        id: 'numerator',
        name: 'Используемый нумератор',
    },
    {
        id: 'file',
        name: 'Файл',
    },
];

export interface IMetaData {
    entity: {
        name: string;
    };
    is_active: boolean;
}

type ObjectKey = {
    [key: string]: any;
};

export const bodyMapper = (data: IMetaData[]) => {
    return data.map((i) => ({ ...i, metaName: i.entity.name, status: i.is_active ? '' : 'Аннулирован' }));
};

export const buttonsLabel: ObjectKey = {
    0: 'Добавить тип документа',
    1: 'Создать шаблон документа',
};

export const tableHeader: ObjectKey = {
    0: documentTypesHeaders,
    1: documentGeneratedHeaders,
};
