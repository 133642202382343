import * as React from 'react';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, Link, Menu } from '@mui/material';
import { Input } from '..';
import NumberWithSpaces from 'shared/helpers/NumberWithSpaces';
import { FieldValueType } from 'domain/field';
import TableDropdown, { ITableDropdown } from './TableDropdown';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { FieldValueTypesArray } from 'pages/Constructor/Create/Field/ConstructorCreateField';
import { TableRelationList } from 'widgets';
import dayjs from 'dayjs';
import i18n from '../../config/i18n/i18n';
import { useTranslation } from 'react-i18next';
import PeriodOfExecution from '../PeriodOfExecution/PeriodOfExecution';
import EditCol from '../EditCol';
import DepartmentsCount from '../../../pages/Distribution/ui/DepartmentsCount';

const ValueTypeDict = FieldValueTypesArray.reduce((acc, item) => ({ ...acc, [item.id]: item?.text }), {});

const generateCell = (
    type: FieldValueType | 'value_type' | 'deadline' | 'edit' | 'departments_count',
    value: any,
    settings?: any,
    id?: string
) => {
    if (
        !(
            value === null ||
            value === undefined ||
            value === '' ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0) ||
            (type !== 'bool' && !value)
        )
    ) {
        const format = `DD.MM.YYYY${type !== 'datetime' ? '' : ' HH:mm:ss'}`;
        switch (type) {
            case 'departments_count': {
                return <DepartmentsCount value={value} id={id} />;
            }
            case 'edit': {
                return <EditCol value={value} id={id} />;
            }
            case 'deadline': {
                return <PeriodOfExecution value={value} />;
            }
            case 'multi_select': {
                return value
                    ?.map((v: string) => {
                        return Object.values(settings?.options?.find((o: any) => v in o))[0];
                    })
                    .join(', ');
            }
            case 'select': {
                return settings?.options?.find((o: any) => value in o)?.[value] || '';
            }
            case 'system': {
                return value?.user
                    ? `${value?.user?.first_name} ${value?.user?.last_name}`
                    : value?.fullname || value?.title || null;
            }
            case 'value_type': {
                return ValueTypeDict[value as keyof typeof ValueTypeDict];
            }
            case 'link':
                if (Array.isArray(value)) {
                    return value
                        ?.map((item: any) => (typeof item === 'object' ? item?.name : item))
                        .join(', ');
                } else {
                    return <a href={value}>{value}</a>;
                }
            case 'int':
                return (
                    <Grid container gap={'12px'} alignItems={'center'}>
                        {NumberWithSpaces(value)}
                    </Grid>
                );
            case 'datetime':
            case 'date':
                if (Array.isArray(value)) {
                    return value
                        ?.map((item: any) =>
                            typeof item === 'object'
                                ? dayjs(item?.name).format(format)
                                : dayjs(item).format(format)
                        )
                        .join(', ');
                } else {
                    return value ? dayjs(value).format(format) : '';
                }
            case 'bool':
                return value ? i18n.t('yes') : i18n.t('no');
            case 'm2m': {
                if (Array.isArray(value)) {
                    if (value) {
                        return <TableRelationList items={value || []} entity={settings?.entity_code || ''} />;
                    }
                    return null;
                } else if (typeof value === 'object') {
                    return value?.name || value?.fullname || '';
                } else {
                    return value || '';
                }
            }
            case 'uuid':
            case 'fk': {
                if (Array.isArray(value)) {
                    if (value) {
                        return <TableRelationList items={value || []} entity={settings?.entity_code || ''} />;
                    }
                    return null;
                } else {
                    const name =
                        typeof value === 'object'
                            ? value?.name || value?.fullname || value?.title || ''
                            : value || '';
                    const entity = settings?.entity_code || value?.entity || '';
                    const isTask = entity === 'tasks';
                    if (!value || !value.id) {
                        return null;
                    }
                    return (
                        <Link href={`/entity/${entity}/${isTask ? `?task_id=${value?.id}` : value?.id}`}>
                            {name}
                        </Link>
                    );
                }
            }
            case 'children': {
                return value || '';
            }
            default:
                if (Array.isArray(value)) {
                    return value
                        ?.map((item: any) =>
                            typeof item === 'object' ? item?.name || item?.fullname || '' : item || ''
                        )
                        .join(', ');
                } else if (typeof value === 'object') {
                    return value?.name || value?.fullname || '';
                } else {
                    return value || '';
                }
        }
    } else {
        return '';
    }
};

interface IEnhancedTableRowProps {
    onDragEnd?: (result?: any) => void;
    onDoubleClick?: (value?: string) => void;
    oneClick?: (value?: string) => void;
    onCheck: (value: string) => void;
    onEdit: (value: boolean) => void;
    edit?: boolean;
    selected: string[];
    header: any[];
    data: any[];
    show_selected?: boolean;
    menu?: ITableDropdown[];
    dnd?: boolean;
    droppableId?: string;
}

export default function EnhancedTableRow({
    onDoubleClick,
    oneClick,
    onCheck,
    onEdit,
    edit,
    data,
    header,
    show_selected = true,
    dnd = false,
    menu,
    droppableId,
    selected,
}: IEnhancedTableRowProps) {
    const { t } = useTranslation();
    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState<number | null>(null);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null);
    };

    return (
        <Droppable droppableId={droppableId || 'app'} direction="vertical">
            {(provided) => (
                <>
                    <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                        {data?.length ? (
                            data?.map((row: any, index: number) => {
                                const isItemSelected = isSelected(row?.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <Draggable
                                        draggableId={row?.id}
                                        index={index}
                                        key={row?.id}
                                        isDragDisabled={!dnd}
                                    >
                                        {(provided) => (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                                sx={{
                                                    pointerEvents: edit && !isItemSelected ? 'none' : 'auto',
                                                    minHeight: '43px',
                                                    height: '43px',
                                                    '&:hover .MuiSvgIcon-colorAction': {
                                                        visibility: 'visible',
                                                    },
                                                }}
                                                ref={provided.innerRef}
                                                {...provided?.draggableProps}
                                                {...provided?.dragHandleProps}
                                            >
                                                {dnd && (
                                                    <TableCell padding="checkbox" sx={{ width: '2.6%' }}>
                                                        <Box {...provided?.dragHandleProps}>
                                                            <DragIndicatorRoundedIcon
                                                                sx={{
                                                                    visibility: 'hidden',
                                                                    mt: 0.6,
                                                                }}
                                                                color={'action'}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                )}
                                                {show_selected ? (
                                                    <TableCell
                                                        padding="checkbox"
                                                        align="center"
                                                        component="td"
                                                        id={labelId}
                                                        scope="row"
                                                        sx={{ width: '2%' }}
                                                    >
                                                        {edit && isItemSelected ? (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => onEdit(false)}
                                                                color={
                                                                    open === row.id ? 'primary' : 'default'
                                                                }
                                                            >
                                                                <CheckCircleRoundedIcon
                                                                    fontSize={'inherit'}
                                                                />
                                                            </IconButton>
                                                        ) : (
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                disabled={edit}
                                                                onClick={() => onCheck(row?.id || '')}
                                                            />
                                                        )}
                                                    </TableCell>
                                                ) : null}
                                                <TableCell padding="checkbox" sx={{ width: '2%', py: 0.5 }}>
                                                    {!!menu?.length && (
                                                        <>
                                                            {edit && isItemSelected ? (
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => onEdit(false)}
                                                                    color={
                                                                        open === row?.id
                                                                            ? 'primary'
                                                                            : 'default'
                                                                    }
                                                                >
                                                                    <CancelRoundedIcon fontSize={'inherit'} />
                                                                </IconButton>
                                                            ) : (
                                                                <>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) =>
                                                                            handleOpen(e, row?.id)
                                                                        }
                                                                        color={
                                                                            open === row?.id
                                                                                ? 'primary'
                                                                                : 'default'
                                                                        }
                                                                        disabled={edit}
                                                                    >
                                                                        <MoreVertIcon fontSize={'inherit'} />
                                                                    </IconButton>
                                                                    <Menu
                                                                        anchorEl={anchorEl}
                                                                        open={open === row?.id}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'left',
                                                                        }}
                                                                    >
                                                                        <TableDropdown
                                                                            menu={menu}
                                                                            item={row}
                                                                            onClose={handleClose}
                                                                        />
                                                                    </Menu>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </TableCell>
                                                {header?.length ? (
                                                    header?.map((field) => {
                                                        return (
                                                            <TableCell
                                                                key={field?.id}
                                                                align={'left'}
                                                                padding={'checkbox'}
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    p: '0 15px',
                                                                    width: '20%',
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (oneClick) {
                                                                        oneClick(row?.id);
                                                                    }
                                                                }}
                                                                onDoubleClick={() =>
                                                                    oneClick
                                                                        ? () => {}
                                                                        : onDoubleClick?.(row?.id)
                                                                }
                                                            >
                                                                <>
                                                                    {typeof row?.[field?.id] !== undefined ||
                                                                    typeof row?.settings?.[field?.id] !==
                                                                        undefined ? (
                                                                        edit && isItemSelected ? (
                                                                            <Input
                                                                                value={
                                                                                    row[field?.id] ||
                                                                                    row?.settings[field?.id]
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            generateCell(
                                                                                field?.value_type || 'str',
                                                                                row?.[field?.id] ??
                                                                                    row?.settings?.[
                                                                                        field?.id
                                                                                    ],
                                                                                field?.settings,
                                                                                row?.id
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            </TableCell>
                                                        );
                                                    })
                                                ) : (
                                                    <TableCell
                                                        padding={'checkbox'}
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            p: '0 15px',
                                                        }}
                                                    ></TableCell>
                                                )}
                                            </TableRow>
                                        )}
                                    </Draggable>
                                );
                            })
                        ) : (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                sx={{
                                    pointerEvents: 'auto',
                                    '&:hover .MuiSvgIcon-colorAction': {
                                        visibility: 'visible',
                                    },
                                }}
                            >
                                <TableCell colSpan={header.length * 2}>
                                    <Typography variant="body1" color="gray" py={1} textAlign="center">
                                        {t('tableEmpty')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </>
            )}
        </Droppable>
    );
}
