import Api from './axios';
import { ICreateDocumentBody, IDocumentTypeList } from '../../domain/documents';

export const documentsService = {
    getDocuments(isGenerated?: boolean) {
        const url = isGenerated ? '/api/v1/documents/document-templates' : `/api/v1/documents/document-types`;
        return Api.get<null, IDocumentTypeList>(url);
    },
    getTemplatesDocuments() {
        return Api.get('/api/v1/documents/document-templates');
    },
    createDocument(body: ICreateDocumentBody) {
        return Api.post('/api/v1/documents/document-types', body);
    },
    updateTypeDocument(body: { name: string; id: string }) {
        return Api.put(`/api/v1/documents/document-types/${body.id}`, {
            is_active: false,
            name: body.name,
        });
    },
    getNumerators() {
        return Api.get('/api/v1/documents/numerators');
    },
};
